import React from "react";
import {Link } from "gatsby";
import Layout from "../components/layout.js"
import Image from "../components/image"
import SEO from "../components/seo"
import Icon from '@material-ui/core/Icon';
import Navlinks from '../components/nav';
import Helmet from "react-helmet";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404" />
    <Helmet defer={true}>
      <body className="four-four" />
    </Helmet>
    <header className="mainHead flexContainer faCenter fjCenter notifactionsHead">      
        <div className="fCol12 menuCtrl">
          <Link className="headerTitle" to={`/#`}>
            <span className="arrowbk"><Icon>arrow_back</Icon></span> 404
          </Link>              
          <Navlinks />
        </div>
      </header>
    <section id="home-content" className="flexContainer secondaryTab">
      <div className="fCol12 maxTablet">        
        <h3>Whoops, looks like the page you are looking for might of moved. </h3>
        <p>Try looking at one of the pages below:<br />
          <ul>
            <li><Link to="/us">U.S. Products</Link></li>
            <li><Link to="/ca">Canada Products</Link></li>
            <li><Link to="/company-information">Company Information</Link></li>
            <li><Link to="/search">Search</Link></li>
          </ul>
        </p>
        
      </div>
    </section>    
  </Layout>
)

export default NotFoundPage
